<template>
  <div
    :class="`invite-share-view ${
      state.isMobile ? '' : ' invite-share-view--pc'
    }`"
  >
    <!-- header 头部 -->
    <div class="is-view-header">
      <img
        class="isv-header-img"
        alt="invitation_envelope.png"
        src="./../assets/invitation_envelope.png"
      />
      <div
        class="isv-header-portrait"
        v-if="state.inviteCodeValid === 2"
        :style="{
          background: `url(${state.avatar}) no-repeat center`,
          backgroundSize: 'cover',
        }"
      ></div>
      <div
        class="isv-header-noportrait"
        v-else-if="state.inviteCodeValid === 1"
      >
        <img
          alt="not_signed.png"
          class="isvh-noportrait-img"
          src="./../assets/not_signed.png"
        />
      </div>
      <div class="isv-header-info">
        <div
          :class="`isvh-info-name ${
            state.inviteCodeValid === 1 ? ' isvh-info-name--own' : ''
          }`"
        >
          {{
            state.inviteCodeValid === 1
              ? "欢迎您加入"
              : state.inviteCodeValid === 2
              ? `${state.username}`
              : ""
          }}
        </div>
        <div
          :class="`isvh-info-company ${
            state.inviteCodeValid === 1 ? ' isvh-info-company--own' : ''
          }`"
        >
          {{
            state.inviteCodeValid === 1
              ? " “派森诺基因云”"
              : state.inviteCodeValid === 2
              ? "邀请您加入“派森诺基因云”"
              : ""
          }}
        </div>
      </div>
      <div class="isv-header-btn" @click="registerRoute">
        {{
          state.inviteCodeValid === 1
            ? "立即加入"
            : state.inviteCodeValid === 2
            ? "立即加入领88云贝"
            : ""
        }}
      </div>
    </div>
    <!-- content内容 -->
    <div class="is-view-content">
      <div class="isv-content-title">基因云（GenesCloud） 能做什么？</div>
      <InviteShareAnalyze
        title="云分析"
        pageType="invite"
        :titleInviteStyle="state.titleAnalyzeStyle"
      />
      <InviteShareCloudtool
        title="云图汇"
        pageType="invite"
        :titleInviteStyle="state.titleCloudStyle"
      />
      <InviteShareTitleComponent
        title="云学院"
        :titleStyle="state.titleSchoolStyle"
      />
      <div class="isv-content-institute">
        <img
          alt="cloud_academy.png"
          class="isvc-institute-img"
          src="./../assets/cloud_academy.png"
        />
        <div class="isvc-institute-info">
          <div class="isvci-info-left">
            <div class="isvcii-left-title">云学院</div>
            <div class="isvcii-left-txt">专业知识，活学妙用</div>
          </div>
          <div class="isvci-info-right">
            <div class="isvcii-right-title">云文档</div>
            <div class="isvcii-right-txt">精品文档，解疑答惑</div>
          </div>
        </div>
      </div>
    </div>
    <!-- footer底部 -->
    <div class="is-view-footer">
      <img src="./../assets/qrcode.jpg" class="isv-footer-qrcode" />
      <div :key="item.txt" :class="item.class" v-for="item in state.footer">
        {{ item.txt }}
      </div>
    </div>
    <div class="is-view-register" @click="registerRoute" v-if="state.isMobile">
      {{ state.btnTitle }}
    </div>
    <van-overlay
      class="is-view-overlay"
      :show="state.shareOverlay"
      @click="state.shareOverlay = false"
    >
      <div class="isv-overlay-guide">
        <img
          src="./../assets/share_tips.png"
          alt="share_tips.png"
          class="isvo-guide-img"
        />
        <span class="isvo-guide-txt">戳右上角，邀请好友</span>
        <van-icon name="close" class="isvo-guide-close" />
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { reactive, getCurrentInstance, onMounted } from "vue";
import { useRouter } from "vue-router";

import personalApi from "@/api/personal";
import { isMobile, friendsCircle } from "@/tools/common";
import InviteShareAnalyze from "@/components/others/InviteShareAnalyze";
import InviteShareCloudtool from "@/components/others/InviteShareCloudtool";
import InviteShareTitleComponent from "@/components/titles/InviteShareTitleComponent";

// const wx = require("weixin-js-sdk");
const shareLink = window.location.href;
const shareTitle = "“友”福同享 | 邀请好友，同赚云贝";
const shareDesc = "一键分享，赠送好友生信分析绘图大礼包！";
const shareImg =
  "https://genescloud.oss-cn-shanghai.aliyuncs.com/web/images/membership/share.png";

const proUrl = "https://www.genescloud.cn/register/";
const testUrl = "https://www.genescloud.cn:9090/register/";
export default {
  name: "InviteShare",
  components: {
    InviteShareAnalyze,
    InviteShareCloudtool,
    InviteShareTitleComponent,
  },
  setup() {
    const router = useRouter();
    const { proxy } = getCurrentInstance();
    const inviteObj = router.currentRoute.value;
    const token = proxy.$cookie.getCookie("tokenid");
    const userInfo = JSON.parse(proxy.$cookie.getCookie("userInfo") || "{}");

    const state = reactive({
      token,
      isMobile,
      avatar: "",
      username: "",
      inviteCodeValid: 0,
      titleCloudStyle: "margin-bottom: 0.1rem",
      titleAnalyzeStyle: "margin-bottom: -0.15rem",
      shareOverlay: isMobile ? (token ? true : false) : false,
      titleSchoolStyle: "margin-top: 0.12rem; margin-bottom: 0.05rem",
      btnTitle:
        inviteObj.query.from === "menu"
          ? token
            ? "去首页"
            : "登录获取专属链接"
          : token
          ? "去首页"
          : "立即注册",

      footer: [
        {
          class: "isv-footer-address",
          txt: "上海市徐汇区银都路218号聚科生物园区2号楼",
        },
        {
          class: "isv-footer-email",
          txt: "gc_support@personalbio.cn",
        },
        {
          txt: "4009-202-898",
          class: "isv-footer-tel",
        },
        {
          txt: "Copyringht 上海派森诺生物科技有限公司",
          class: "isv-footer-company",
        },
        {
          txt: "2019-2022 版权所有沪ICP备16045426号-2",
          class: "isv-footer-copyright",
        },
      ],
    });

    // 点击跳转到注册页
    const registerRoute = () => {
      if (state.isMobile) {
        router.push(`${token ? "/layout/home" : "/authBefore"}`);
      } else {
        location.href = `${
          process.env.NODE_ENV === "development" ? testUrl : proUrl
        }${inviteObj.params.inviteCode}`;
      }
      if (state.inviteCodeValid === 2 && !userInfo.user_info) {
        proxy.$cookie.setCookie(
          "userInfo",
          JSON.stringify({
            avatar: state.avatar,
            username: state.username,
            user_info: inviteObj.params.inviteCode,
          }),
          25,
          "/"
        );
      }
    };

    const checkCode = async () => {
      const res = await proxy.$request.handleFetchGet(
        personalApi.checkcode + `?code=${inviteObj.params.inviteCode}`
      );

      if (res.code === 2000) {
        state.avatar = res.avatar;
        state.inviteCodeValid = 2;
        state.username = res.username;
        friendsCircle(shareTitle, shareDesc, window.location.href, shareImg);
      } else {
        if (token && inviteObj.query.from === "menu") {
          proxy.$store
            .dispatch("getUserInfoAction")
            .then((res) => {
              if (res.code === 2000) {
                state.inviteCodeValid = 2;
                state.username = res.username;
                state.avatar = res.user_avatar;
                friendsCircle(
                  shareTitle,
                  shareDesc,
                  window.location.origin +
                    window.location.pathname +
                    `/${res.user_info}`,
                  shareImg
                );
              } else {
                state.inviteCodeValid = 1;
              }
            })
            .catch((err) => {});
        } else {
          state.inviteCodeValid = 1;
        }
      }
    };

    onMounted(() => {
      checkCode();
    });
    return {
      state,
      registerRoute,
    };
  },
};
</script>
