<template>
  <div class="invite-share-analyze">
    <div class="is-analyze-title" v-if="pageType === 'home'">{{ title }}</div>
    <InviteShareTitleComponent
      v-else
      :title="title"
      :titleStyle="titleInviteStyle"
    />
    <div
      :class="`is-analyze-intro ${
        state.isMobile ? '' : ' is-analyze-intro--pc'
      }`"
    >
      <img
        class="isa-intro-img"
        :src="pageType === 'home' ? state.bannerArr[0] : state.bannerArr[1]"
      />
      <div
        :class="[
          'isa-intro-content',
          pageType === 'invite' ? 'isa-intro-content--invite' : '',
          state.isMobile ? '' : 'isa-intro-content--pc',
        ]"
      >
        <div
          v-for="item in state.analysisArr"
          :class="item.class"
          :key="item.class"
        >
          <div :class="item.subClass" v-for="value in item.arr" :key="value">
            <IconFontSymbol
              icon="icondecorate"
              :style="state.dotStyle"
              v-if="pageType === 'home'"
            />
            <div :class="item.dotClass" v-else></div>
            <span :class="item.txtClass">{{ value }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive } from "vue";

import { isMobile } from "@/tools/common";
import IconFontSymbol from "@/components/IconFontSymbol.vue";
import InviteShareTitleComponent from "@/components/titles/InviteShareTitleComponent";

const dotStyle = "font-size: 0.11rem; margin-right: 0.05rem";
export default {
  name: "InviteShareAnalyze",
  props: {
    title: {
      type: String,
      default: "",
    },
    pageType: {
      type: String,
      default: "home",
    },
    // analysisArr: {
    //   type: Array,
    //   default: [],
    // },
    titleHomeStyle: {
      type: String,
      default: "",
    },
    titleInviteStyle: {
      type: String,
      default: "",
    },
  },
  components: {
    IconFontSymbol,
    InviteShareTitleComponent,
  },
  setup() {
    const state = reactive({
      isMobile,
      dotStyle,
      bannerArr: [
        require("./../../assets/h_cloud_analytics.png"),
        require("./../../assets/cloud_analytics.png"),
      ],
      analysisArr: [
        {
          class: "isai-content-left",
          subClass: "isaic-left-txt",
          dotClass: "isaicl-txt-dot",
          txtClass: "isaicl-txt-merit",
          arr: ["纯正的QIIME2云分析", "敏捷式交互分析", "个性化专业配色"],
        },
        {
          class: "isai-content-right",
          subClass: "isaic-right-txt",
          dotClass: "isaicr-txt-dot",
          txtClass: "isaicr-txt-merit",
          arr: ["转录组一键智能分析", "自定义分组方案", "自由定制报告内容"],
        },
      ],
    });
    return {
      state,
    };
  },
};
</script>
<style lang="scss" scoped>
.invite-share-analyze {
  display: flex;
  flex-direction: column;
  align-items: center;

  .is-analyze-title {
    margin-bottom: -0.2rem;
    margin-top: 0.2rem;
    font-size: 0.18rem;
    color: rgba(0, 0, 0, 0.85);
  }
  .isa-intro-img {
    width: 3.6rem;
    margin-bottom: 0.1rem;
  }

  .is-analyze-intro {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .isa-intro-content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    width: 3.15rem;
    font-size: 0.14rem;
  }

  .isaic-left-txt,
  .isaic-right-txt {
    display: flex;
    align-items: center;
    margin: 0.15rem 0;
    color: rgba(0, 0, 0, 0.85);
  }

  .isaicl-txt-dot,
  .isaicr-txt-dot {
    position: relative;
    top: 0.01rem;
    width: 0.07rem;
    height: 0.07rem;
    margin-right: 0.05rem;
    border-radius: 50%;
    background: #fff265;
    // background: #537efe;
  }

  .isa-intro-content--invite {
    width: 3.5rem;
    background: rgba(255, 255, 255, 0.13);
    .isaic-left-txt,
    .isaic-right-txt {
      color: #fff;
    }
  }

  .is-analyze-intro--pc {
    flex-direction: row;
    margin-top: 0.2rem;
  }

  .isa-intro-content--pc {
    width: 2.4rem;
    margin-left: 0.2rem;
    font-size: 0.1rem;
  }
}
</style>
