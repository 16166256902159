<template>
  <div class="invite-share-component-title" :style="titleStyle">
    <IconFontSymbol
      icon="icontext_decoration"
      :boxStyle="{
        display: 'flex',
        alignItems: 'center',
        width: '1.2rem',
        height: '0.25rem',
      }"
      style="font-size: 1.2rem"
    />
    <span class="is-component-title-txt">{{ title }}</span>
  </div>
</template>
<script>
import IconFontSymbol from "@/components/IconFontSymbol";
export default {
  name: "InviteShareTitleComponent",
  props: ["title", "titleStyle"],
  components: {
    IconFontSymbol,
  },
};
</script>
<style lang="scss" scoped>
.invite-share-component-title {
  display: flex;
  justify-content: center;
  position: relative;
  font-size: 0.18rem;
  color: #ffffff;
  .is-component-title-txt {
    position: absolute;
    top: -0.05rem;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
