<template>
  <div class="invite-share-cloudtool" :style="boxStyle">
    <div class="is-cloudtool-title" v-if="pageType === 'home'">{{ title }}</div>
    <InviteShareTitleComponent
      :title="title"
      v-else
      :titleStyle="titleInviteStyle"
    />
    <div
      :class="`is-cloudtool-intro ${isMobile ? '' : ' is-cloudtool-intro--pc'}`"
    >
      <img
        class="isc-intro-img"
        :src="pageType === 'home' ? bannerArr[0] : bannerArr[1]"
      />
      <p
        :class="[
          isMobile ? 'isc-intro-txt' : 'isc-intro-txt--pc',
          pageType === 'invite' ? 'isc-intro-txt--invite' : '',
        ]"
      >
        支持热图、盒形图、RDA冗余分析、韦恩图、关系图、密度图、桑基图等一百多款图表种类。
      </p>
    </div>
    <div class="is-cloudtool-result">
      <img
        :key="img"
        :src="img"
        v-for="img in pageType === 'invite' ? imgList.slice(0, 6) : imgList"
        :class="[
          isMobile ? 'isc-result-img' : 'isc-result-img--pc',
          pageType === 'invite' ? 'isc-result-img--invite' : '',
        ]"
      />
    </div>
  </div>
</template>
<script>
import { reactive } from "vue";
import { isMobile } from "@/tools/common";
import InviteShareTitleComponent from "@/components/titles/InviteShareTitleComponent";
export default {
  name: "InviteShareCloudtool",
  props: {
    title: {
      type: String,
      default: "",
    },
    pageType: {
      type: String,
      default: "home",
    },
    boxStyle: {
      type: String,
      default: "",
    },
    titleHomeStyle: {
      type: String,
      default: "",
    },
    titleInviteStyle: {
      type: String,
      default: "",
    },
  },
  components: {
    InviteShareTitleComponent,
  },
  setup() {
    const state = reactive({
      isMobile,
      bannerArr: [
        require("./../../assets/h_yuntuhui.png"),
        require("./../../assets/yuntuhui.png"),
      ],
      imgList: [
        require("./../../assets/cloud_tool_hot.jpg"),
        require("./../../assets/cloud_tool_rda.jpg"),
        require("./../../assets/cloud_tool_relative.jpg"),
        require("./../../assets/cloud_tool_errorbar.jpg"),
        require("./../../assets/cloud_tool_boxplot.jpg"),
        require("./../../assets/cloud_tool_stripbar.jpg"),
        require("./../../assets/cloud_tool_chord.jpg"),
        require("./../../assets/cloud_tool_verticalbar.jpg"),
      ],
    });
    return {
      ...state,
    };
  },
};
</script>
<style lang="scss" scoped>
.invite-share-cloudtool {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.2rem;

  .is-cloudtool-title {
    margin-bottom: 0.1rem;
    font-size: 0.18rem;
    color: rgba(0, 0, 0, 0.85);
  }

  .is-cloudtool-intro {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .isc-intro-img {
    width: 3.6rem;
    margin-bottom: 0.1rem;
  }

  .isc-intro-txt {
    margin-bottom: 0.15rem;
    padding: 0 0.25rem;
    line-height: 1.5;
    font-size: 0.12rem;
    color: rgba(0, 0, 0, 0.6);
  }

  .isc-intro-txt--invite {
    color: #fff;
  }

  .is-cloudtool-intro--pc {
    flex-direction: row;
    margin: 0.2rem 0;
  }

  .isc-intro-txt--pc {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.3rem;
    height: 0.63rem;
    padding-left: 0.1rem;
    // padding: 0 0.1rem;
    margin-left: 0.2rem;
    color: #fff;
    font-size: 0.1rem;
    background: rgba(255, 255, 255, 0.09);
    border-radius: 4px;
  }

  .is-cloudtool-result {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    // justify-content: space-around;
  }

  @mixin isc-result-img {
    margin-right: 0.1rem;
    margin-bottom: 0.1rem;
    border-radius: 12px;
    border: 1px solid #e1e1e1;
  }

  .isc-result-img {
    width: 1.62rem;
    height: 1.39rem;
    @include isc-result-img;
  }

  .isc-result-img:nth-child(2n) {
    margin-right: 0;
  }

  .isc-result-img--invite {
    border: none;
  }

  .isc-result-img--pc {
    width: 2rem;
    @include isc-result-img;
  }
  .isc-result-img--pc:nth-child(3n) {
    margin-right: 0;
  }
}
</style>
